<template>
  <div class="home">
    <div class="home-banner">
      <a href="/products/?collections=Burberry%2520-%2520FW24" class="relative block text-center" target="_blank">
        <FocalImage class="lg:block hidden" src="https://cdn.zando.co.za/cms/sunglasshut/primary-desktopbanner_new.jpg"
          alt="Sale_Banner" />
        <FocalImage class="lg:hidden md:block" src="https://cdn.zando.co.za/cms/sunglasshut/primary-mobilebanner_new.jpg"
          alt="Sale_Banner" />

        <button class="button button-white home-banner-cta hidden">Shop now</button>
      </a>

      <div class="home-banner-text container mx-auto">
        <TextComponent heading="Burberry - FW24" text="Discover the new Burberry Fall-Winter collection, with exclusive styles only available at Sunglass Hut."
          button="Shop Now" link="/products/?collections=Burberry%2520-%2520FW24" />
      </div>
    </div>
    <div class="mx-auto md:pb-12 sm:pb-6 mt-8">
      <div class="grid gap-0 lg:grid-cols-6 md:grid-cols-3 sm:grid-cols-2">
        <HomeProducts />
      </div>
    </div>
    <div id="promo1"></div>
    <div class="-inner">
      <div><a href="/products/?collections=TREND%253A%2520Essentials">
          <img src="https://cdn.zando.co.za/cms/sunglasshut/trend-essetial-1200x600-02.jpg" class="block mx-auto" alt="Most Loved Styles">
        </a>
        <div class="home-text">
          <TextComponent heading="TREND: Essentials"
            text="Not-so-basic classics make for effortless charm with longevity" button="Discover more"
            link="/products/?collections=TREND%253A%2520Essentials" />
        </div>
      </div>
    </div>
    <!--
     <div class="m-4">
       <div class="grid gap-4 lg:grid-cols-2 md:grid-cols-1">
         <div><a href="#">
           <img src="../../assets/Neutrals-1200x600_02.jpg" alt="Sport Luxe">
         </a>
           <div class="home-text">
             <TextComponent heading="Trend: Sport Luxe" text="Mixing performance with fashion-forward designs" button="Discover more" link="#" />
           </div>
         </div>
         <div> <a href="products/?collections=Neutrals%2520Trend">
           <img src="../../assets/Neutrals-1200x600_02.jpg" alt="Trend: Neutrals">
         </a>
           <div class="home-text">
             <TextComponent heading="Trend: Neutrals" text="Colourways that mirror nature" button="Discover the collection"
                            link="/products/?collections=Neutrals%2520Trend" />
           </div>
         </div>
       </div>
     </div>-->
    <Carousel :autoplay="10000" :wrap-around="true" class="display-none">
      <Slide v-for="slide in slides" :key="slide.id">
        <div class="carousel__item">
          <div class="-inner" :id="slide.anchor">
            <a :href="slide.link">
              <img :src="slide.image" :alt="slide.title" />
            </a>
          </div>
          <div class="home-text home-text-secondary hide-h2">
            <TextComponent :heading="slide.title" :text="slide.text" :button="slide.button" :link="slide.link" />
          </div>
        </div>
      </Slide>
      <template #addons>
        <Navigation class="" />
        <Pagination class="" />
      </template>
    </Carousel>
    <div class="mx-auto md:pb-12 sm:pb-6 display-none">
      <div class="grid gap-0 lg:grid-cols-6 md:grid-cols-3 sm:grid-cols-2">
        <HomeProducts2 />
      </div>
    </div>
    <div class="home-text">
      <h3 class="text-left m-5">Shop by brands</h3>
      <ul class="homepage-brands">
        <li v-for="item in brands" :key="item.name" class="inline-flex brightness-110 hover:brightness-150">
          <a class="darken" v-if="item.name != 'Ray-Ban Kids' &&
            item.name != 'Arnette' &&
            item.name != 'Ralph' &&
            item.name != 'Bvlgari' &&
            item.name != 'Emporio Armani' &&
            item.name != 'Valentino' &&
            item.name != 'Valentino' &&
            item.name != 'Scuderia Ferrari' &&
            item.name != 'Prada Linea Rossa' &&
            item.name != 'Costa Del Mar'
            " v-bind:href="item.url">
            <img :src="`${imageBase}${item.img}`" /></a>
        </li>
      </ul>
    </div>

    <div class="home-footer">
      <img class="w-full" :src="`${imageBaseCms}WebBanner_summer_generic.jpg`" />
    </div>
  </div>
</template>

<script>
import "./Home.scss";
//import TextoverImage from "../../components/molecules/TextoverImage";
import HomeProducts from "../../components/molecules/HomeProducts";
import HomeProducts2 from "../../components/molecules/HomeProducts2";
import TextComponent from "../../components/molecules/TextComponent";
import FocalImage from "../../components/atoms/FocalImage";
import data from "../../appData/index";
import { useMeta } from "vue-meta/dist/vue-meta.esm-browser";

import { Carousel, Pagination, Slide } from "vue3-carousel";

import "vue3-carousel/dist/carousel.css";

import { onMounted } from "vue";

export default {
  name: "Home",
  components: {
    FocalImage,
    //TextoverImage,
    HomeProducts,
    HomeProducts2,
    TextComponent,
    Carousel,
    Slide,
    Pagination,
  },
  data() {
    return {
      brands: data.brandInfo.list,
      excludeList: "Ray-Ban Kids",
      slides: data.homeslider.slides,
    };
  },
  created() {

  },
  setup() {
    useMeta({
      title:
        "Sunglass Hut® South Africa Online Store | Sunglasses for Women & Men",
      description:
        "Sunglass Hut® South Africa Online Store | Sunglasses for Women & Men",
    });

    const scrollToAnchor = (anchor) => {
      const element = document.getElementById(anchor);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    };

    onMounted(() => {
      //console.log(window.location);
      const hash = window.location.hash;
      if (hash) {
        // Remove the '#' character from the hash
        const anchor = hash.slice(1);
        scrollToAnchor(anchor);
      }
    });

    return {};
  },
};
</script>
